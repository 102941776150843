import { defineStore } from "pinia";
import {fdb} from "@/firebase";
import {onValue, ref, child, set, get, update} from "firebase/database";
import dayjs from "dayjs";
import {db} from "@/db"
import {PageSettings} from "@/stores/page";
import {Statistic} from "@/stores/statistic";


export const RTDB = defineStore('realtimedb', {
  state: () => ({
    actual_power: 0,
    created_at: null,
    id: null,
    loading: false,
    energy_consumption: 0,
    energy_settings: {
      current: 0,
      lp: 0,
      maxKWh: 0,
      phase: 0
    },
    energychart: {},
    powerchart: {},
    transaction_cur: "EUR",
    transaction_end: null,
    ordered_at: null,
    parkingspace: 0,
    payed: 0.0,
    product_name: "",
    progress: 0,
    uid: null,
    provider: null,
    fcmtoken: null,
    adv_text: "Ihr spezialist für Parkschein- und Ticketautomaten",
    adv_title: "Technic Gerätebau GmbH",
    adv_link: "https://www.technic-gmbh.at/",
    adv_link_title: "Technic Gerätebau GmbH",
    customer: null,
    is_customer: false,
    table_load_triggered: false,
  }),
  actions: {
    async ConvertChart(chartdata = null){
      function ConvertTime(value){
        return dayjs(value).format("HH:mm:ss");
      }
      let newlabels = [];
      if(!chartdata || typeof chartdata !== "object"){
        return chartdata;
      }
      for(let i = 0; i < chartdata.labels.length; i++){
        newlabels.push(ConvertTime(chartdata.labels[i]));
      }
      chartdata.labels = newlabels;
      return chartdata;
    },
    async loadData(reference = null){
      if(reference){this.uid = reference}
      if(!this.uid){return ;}
      const query = ref(fdb, this.uid)
      const pgs = PageSettings();

      onValue(query, (snapshot)=>{
        if(snapshot.exists()){
          const data = snapshot.val();
          if(data.actual_power && this.actual_power !== data.actual_power){
            this.actual_power = data.actual_power;
          }
          if(data.created_at && this.created_at !== data.created_at){
            this.created_at = data.created_at;
          }
          if(!this.id){
            this.id = data.id;
          }
          if('statistic_enabled' in data && !pgs.statistic_enabled && data.statistic_enabled){
            pgs.statistic_enabled = data.statistic_enabled;
          }
          if(data.energy_consumption && this.energy_consumption !== data.energy_consumption){
            this.energy_consumption = data.energy_consumption;
          }
          if(data.energy_settings){
            this.energy_settings = data.energy_settings;
          }

          this.loading = data.loading;
          this.ConvertChart(data.energychart).then((result)=>{this.energychart = result})
          this.ConvertChart(data.powerchart).then((result) => {this.powerchart = result})
          if('progress' in data){
            this.progress = data.progress;
          }
          if('settings' in data){
            if(pgs.background_gradient !== data.settings.background_gradient){pgs.background_gradient = data.settings.background_gradient}
            if(!pgs.receipt_download && data.settings.receipt_download){pgs.receipt_download = data.settings.receipt_download}
            if(pgs.provider_logo !== data.settings.logo){
              pgs.provider_logo = data.settings.logo
              if(pgs.provider_logo && !pgs.provider_logo.startsWith("http")){
                pgs.provider_logo = "https://datacenter-dev.technic-gmbh.at"+pgs.provider_logo;
              }
            }
          }
          if('transaction' in data){
            if(this.transaction_cur !== data.transaction.cur){this.transaction_cur = data.transaction.cur}
            if(this.transaction_end !== data.transaction.end){this.transaction_end = data.transaction.end}
            if(this.ordered_at !== data.transaction.ordered_at){this.ordered_at = data.transaction.ordered_at}
            if(this.parkingspace !== data.transaction.parkingspace){this.parkingspace = data.transaction.parkingspace}
            if(this.payed !== data.transaction.payed){this.payed = data.transaction.payed}
            if(this.product_name !== data.transaction.tr_name){this.product_name = data.transaction.tr_name}
            if(this.customer !== data.transaction.customer){
              this.customer = data.transaction.customer;
              if(this.customer){this.is_customer = true}
            }
          }
          if('adv' in data){
            if(this.adv_text !== data.adv.text){this.adv_text = data.adv.text}
            if(this.adv_title !== data.adv.title){this.adv_title = data.adv.title}
            if(this.adv_link !== data.adv.link){this.adv_link = data.adv.link}
            if(this.adv_link_title !== data.adv.link_title){this.adv_link_title = data.adv.link_title}
          }
          if(pgs.statistic_enabled){
            this.storeCurrentCharge()
          }
        }
      }, (error) => {
        console.error("error = ", error);
      })
    },
    async pushToken(token=null){
      if(token && this.uid){
        if(this.fcmtoken === token){return}
        this.fcmtoken = token
        const query = ref(fdb, '/'+this.uid)
        const querychild = child(query, '/fcmtoken')
        const fcmtokens = await get(querychild)
        if(!fcmtokens.val()){
          return await set(querychild, [token])
        }
        let fcmt = [];
        for(let i = 0; i < fcmtokens.val().length; i++){
          fcmt.push(fcmtokens.val()[i]);
        }
        if(fcmt.indexOf(token) !== -1){
          return;
        }
        fcmt.push(token)
        return await update(query, {"fcmtoken": fcmt})
      }
    },
    async storeCurrentCharge(){
      const rtdbquery = ref(fdb, '/'+this.uid)
      const rtdbdata = await get(rtdbquery)
      const statisticdb = Statistic()

      let data = {}
      let existing_data = await statisticdb.getItem(this.uid)
      if(existing_data){
        return;
      }
      if(rtdbdata.val()){
        Object.keys(rtdbdata.val()).forEach(key=>{data[key] = rtdbdata.val()[key]})
      }

      data['energychart'] = this.energychart;
      data['powerchart'] = this.powerchart;
      // cleanup object
      let to_delete = ['settings', 'adv', 'loading']
      for(let i = 0; i < to_delete.length; i++) {
        if (to_delete[i] in data) {
          delete data[to_delete[i]]
        }
      }
      // if(existing_data){
      //   await statisticdb.deleteItem(this.uid)
      // }
      return await db.charges.put(JSON.parse(JSON.stringify(data)), this.uid)
    }
  }
})
