<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="text-sm text-center copyright text-muted text-lg-start">
            ©{{ new Date().getFullYear() }}
            <a href="https://www.technic-gmbh.at" class="font-weight-bold" target="_blank">Technic-GmbH</a>
          </div>
        </div>
        <div class="col-lg-6">
          <ul class="nav nav-footer justify-content-center justify-content-lg-end">
            <li class="nav-item">
              <a href="https://www.technic-gmbh.at" class="nav-link" target="_blank">Technic-GmbH</a>
            </li>
            <li class="nav-item">
              <a href="https://technic-gmbh.at/ueber-uns/" class="nav-link" target="_blank">Über uns</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>

</script>
