<template>
  <div :class="`landing-bg h-100 ${bg_gradient_color} position-fixed w-100`"></div>
  <main class="main-content position-relative max-height-vh-100 h-100">
      <navbar />
      <router-view />
      <app-footer />
  </main>
</template>

<script setup>
import Navbar from "@/views/NavBar.vue";
import {PageSettings} from "@/stores/page";
import {onMounted, computed, watch} from "vue";
import AppFooter from "@/views/AppFooter.vue";
import {RTDB} from "@/stores/metric";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {useRoute, useRouter} from "vue-router";
// import TcaSidenav from "@/views/Sidenav/TcaSidenav.vue";

const router = useRouter();
const route = useRoute();
const messaging = getMessaging();
const pgs = PageSettings()
const rtdb = RTDB()

const bg_gradient_color = computed(() => {
    if(['primary', 'secondary', 'success', 'info', 'warning', 'light', 'dark'].indexOf(pgs.background_gradient) !== -1){
        return 'bg-gradient-' + pgs.background_gradient;
    }
    return 'bg-gradient-light';
})

watch(pgs.$state, (state)=> {
  if(state.uid || state.provider || state.background_gradient || state.provider_logo || state.duid){
    if(state.uid.includes("-")){state.uid = state.uid.replace("-", "")}
    localStorage.setItem("pgstate", JSON.stringify(state));
  }
}, {deep: true}
)


watch(()=>rtdb.fcmtoken, function(){
    if(rtdb.uid && rtdb.fcmtoken){
        rtdb.pushToken(rtdb.fcmtoken)
    }
})

onMounted(async () => {
  await router.isReady();
  if(route.query.uid){
    let uid = route.query.uid;
    if(uid.includes("-")){uid = uid.replace("-", "")}

    pgs.$patch({uid: uid, provider: route.query.provider});
    rtdb.$patch({uid: uid, provider: route.query.provider});
  } else {
    await loadPersistentState();
  }
  setTimeout(()=>{loadToken()}, 5000)
})

onMessage(messaging, (payload) => {
  console.log(payload);
});

async function loadPersistentState(){
  const state = await JSON.parse(localStorage.getItem("pgstate"));
  if(state){
    if(state.uid || state.provider || state.background_gradient || state.provider_logo){
      if(state.uid.includes("-")){state.uid = state.uid.replace("-", "")}
      pgs.$patch(state);
      rtdb.$patch({uid: state.uid, provider: state.provider, background_gradient: state.background_gradient});
    }
  }
}

async function loadToken(){
    const newSW = await navigator.serviceWorker.register('/static/vue/dist/firebase-messaging-sw.js');
    getToken(messaging, { vapidKey: "BPSKO10u0mwMil6qYA9Wj3t3Ylg6U-gNcwJ9w2qKGEi3EcYZeKz1JuISNWRttuazY0E0z2QNZWqXDPvOUdfvywI",
    serviceWorkerRegistration: newSW}).then((currentToken) => {
        if (currentToken) {
          rtdb.fcmtoken = currentToken;
        } else {
          console.log('No registration token available. Request permission to generate one.');
          requestPermission();
        }
    });
}

function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            loadToken();
        }
    })
}

</script>

<style>

</style>
