<template>
  <div class="container-fluid mt-6">
    <div class="row mb-5 mt-5">
      <div class="col-lg-3">
        <side-nav :charts="has_charts" :receipt="has_receipt"/>
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <argon-button full-width color="success" size="lg" variant="gradient" @click="goBack()">Zurück</argon-button>
        <div id="charging-settings" class="card mt-4">
          <div class="card-header">
            <h5><i class="ni ni-spaceship me-2 text-dark"></i> Information zur Ladung</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row" v-if="energy_settings">
              <div class="col-6">
                <dl class="row">
                  <div class="row" v-for="item in Object.keys(energy_settings)" :key="item">
                    <dt class="col-sm-4">{{ translate_keys(item) }}</dt>
                    <dd class="col-sm-2">{{ get_units(item, energy_settings[item]) }}</dd>
                  </div>
                </dl>
              </div>
              <div class="col-6">
                <dl class="row">
                  <dt v-if="info.finish" class="col-sm-6">Ladung Abgeschlossen</dt>
                  <dt v-else class="col-sm-6">Ladung zu {{info.progress}}% Abgeschlossen</dt>
                  <dd class="col-sm-6"></dd>
                  <dt class="col-sm-4">Geladene Energie</dt>
                  <dd class="col-sm-6">{{ get_units("KWh", info.energy_consumption) }}</dd>
                  <dt class="col-sm-4">Ladezeit</dt>
                  <dd class="col-sm-6">{{ loadingDateDiff }}</dd>
                  <dt v-if="info.finish && 'powerMeter' in transaction" class="col-sm-4">Zähler Signatur</dt>
                  <dt v-if="info.finish && 'powerMeter' in transaction" class="col-sm-6">
                    <i v-if="billsigncheck_valid" class="ni ni-check-bold me-2 text-dark"></i>
                    <i v-else class="ni ni-fat-delete me-2 text-dark"></i>
                  </dt>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div id="transaction-info" class="card mt-4">
          <div class="card-header">
            <h5><i class="ni ni-shop me-2 text-dark"></i>Transaktion</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row" v-if="transaction">
                <dl class="row">
                  <dt class="col-sm-3">Transaktions ID</dt>
                  <dd class="col-sm-3">{{ transaction.id }}</dd>
                  <dt class="col-sm-3">Kaufdatum</dt>
                  <dd class="col-sm-3">{{ $filters.formatDateTime(transaction.ordered_at) }}</dd>
                  <dt class="col-sm-3">Bezahlt</dt>
                  <dd class="col-sm-3">{{ get_units(transaction.cur, transaction.payed) }} </dd>
                  <dt class="col-sm-3">Produkt</dt>
                  <dd v-if="transaction.tr_name" class="col-sm-3">{{ transaction.tr_name }}</dd>
                  <dd v-else class="col-sm-3">&mdash;</dd>
                  <dt class="col-sm-3">Ladezeit / Parkzeit Ende</dt>
                  <dd class="col-sm-3">{{ $filters.formatDateTime(transaction.end) }}</dd>
                  <dt class="col-sm-3">Kundennummer</dt>
                  <dd v-if="transaction.customer" class="col-sm-3">{{ transaction.customer }}</dd>
                  <dd v-else class="col-sm-3">&mdash;</dd>
                </dl>
            </div>
          </div>
        </div>

        <div v-if="has_receipt" id="transaction-receipt" class="card mt-4">
          <div class="card-header">
            <h5><i class="ni ni-check-bold me-2 text-dark"></i>Beleg</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row justify-content-center" v-if="transaction">
              <div class="col-10 col-sm-3" v-if="transaction.receipt">
                <pre v-html="prep_receipt($filters.b64DecodeUnicode(transaction.receipt))"></pre>
              </div>
            </div>
          </div>
        </div>

        <div v-if="has_charts" id="charging-charts" class="card mt-4">
          <div class="card-header">
            <h5><i class="ni ni-chart-bar-32 me-2 text-dark"></i>Charts</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row" v-if="energychart || powerchart">
              <div class="col-12">
                <default-line-chart v-bind="{
                  id: 'ladeenergie-verlauf',
                  title: 'Verlauf Energie',
                  height: '250',
                  chart: energychart,
                  pointradius: 0}"
                />
                <br />
              </div>
              <div class="col-12">
                <default-line-chart v-bind="{
                  id: 'ladeleistung-verlauf',
                  title: 'Verlauf Ladeleistung',
                  height: '250',
                  chart: powerchart,
                  pointradius: 0
                  }"
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <argon-button full-width color="success" size="lg" variant="gradient" @click="goBack()">Zurück</argon-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {Statistic} from "@/stores/statistic";
import {useRoute, useRouter} from "vue-router";
import DefaultLineChart from "@/components/Charts/DefaultLineChart.vue";
import SideNav from "@/views/statisticpage/components/detail/SideNav.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import axios from "axios";
import dayjs from "dayjs";


const router = useRouter()
const route = useRoute()
const statistic = Statistic()
const energy_settings = ref();
const transaction = ref();
const energychart = ref();
const powerchart = ref();
const info = ref({});
const has_receipt = ref(false);
const has_charts = ref(false);
const billsigncheck_valid = ref(false);
const loadingDateDiff = ref("");

onMounted(async () => {
  const data = await statistic.getItem(await route.params.id)
  if("energy_settings" in data) {
    energy_settings.value = data.energy_settings
  }
  if("transaction" in data) {
    transaction.value = data.transaction
    if("receipt" in data.transaction) {
      has_receipt.value = true
    }
    if("powerMeter" in data.transaction) {
      billsigncheck_valid.value = await CheckBillSign(data.transaction.powerMeter)
    }
  }
  if("energychart" in data) {
    energychart.value = data.energychart
    has_charts.value = true
  }
  if("powerchart" in data) {
    powerchart.value = data.powerchart
    has_charts.value = true
  }
  if("loading_start" in data && "loading_end" in data) {
    loadingDateDiff.value = await getDiffDate(data.loading_start, data.loading_end);
  }
  for (let key in data) {
    if (key in data) {
      if(["energy_settings", "transaction", "energychart", "powerchart"].indexOf(key) === -1) {
        info.value[key] = data[key]
      }
    }
  }
})

async function getDiffDate(start, end) {
  const date1 = dayjs(start);
  const date2 = dayjs(end);
  const diff = date2.diff(date1, 'day', true);
  const days = Math.floor(diff);
  const hours = Math.floor((diff - days) * 24);
  return `${days} Tage, ${hours % 24} Stunden`
}

function prep_receipt(receipt) {
  return receipt.replace(/(?:\r\n|\r|\n)/g, '<br />')
}

function translate_keys(key){
  switch (key.toLowerCase()) {
    case "energy_consumption":
      return "Energieverbrauch"
    case "maxkwh":
      return "Maximale Energie Abgabe"
    case "current":
      return "Stromstärke"
    case "lp":
      return "Ladepunkt"
    case "phase":
      return "Phasen"
    default:
      return key
  }
}

function get_units(key, value){
  switch (key.toLowerCase()) {
    case "energy_consumption":
    case "maxkwh":
    case "kwh":
      return `${value} KWh`
    case "current":
      return `${value} A`
    case "eur":
      return `${value} €`
    default:
      return `${value}`
  }
}

async function goBack() {
  await router.push({name: "Statistic"})
}

async function CheckBillSign(powermeterdata = {}){
  if(!powermeterdata) {return}
  const resp = await axios.post("/api/v1/transaction/checkbillsign", powermeterdata.billingData[0])
  return resp.data.valid
}

</script>

<style scoped>

pre {
 background-color: rgba(150, 153, 158, 0.10);
}

</style>
