import { defineStore } from "pinia";
import bootstrap from "bootstrap/dist/js/bootstrap.min";

export const PageSettings = defineStore('PageSettings', {
  state: () => ({
    duid: null,
    uid: null,
    provider: null,
    provider_logo: null,
    color: "",
    sidebarType: "bg-white",
    darkMode: false,
    background_gradient: "info",
    showNavs: false,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isNavFixed: false,
    layout: "landing",
    statistic_enabled: true,
    receipt_download: false,
    bootstrap,
  }),
  actions: {
    navbarMinimize() {
      const sidenav_show = document.querySelector("#app");

      if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        this.isPinned = true;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        this.isPinned = false;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && window.innerWidth < 1200 ) {
        sidenav_show.classList.add("g-sidenav-pinned");
        this.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        this.isPinned = true;
      }
    },
    async loadPersistentStore(key = 'pgstate'){
      if(localStorage.getItem(key)){
        try {
          const state = await JSON.parse(localStorage.getItem(key));
          if(state){
            this.state = state;
          }
        } catch (e) {
          //
        }
      }
    }
  }
})
