import { createRouter, createWebHistory } from "vue-router";
import ChargingPage from "@/views/chargingpage/ChargingPage.vue";
import StatisticPage from "@/views/statisticpage/StatisticPage.vue";
import StatisticDetailPage from "@/views/statisticpage/StatisticDetailPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: ChargingPage
  },
  {
    path: "/statistic",
    name: "Statistic",
    component: StatisticPage
  },
  {
    path: "/statistic/detail/:id",
    name: "StatisticDetail",
    component: StatisticDetailPage
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});

export default router;
