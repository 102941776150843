<template>
  <div class="col-12">
    <div class="row">
      <div class="mt-4 col-lg-3 col-md-6 col-12 mt-lg-0">
        <mini-statistics-card
          title="Gesamt Geladene Energie"
          :value="LoadedEnergy"
          description="&nbsp;"
          :icon="{
            component: 'ni ni-sound-wave',
            background: 'bg-gradient-dark',
            shape: 'rounded-circle'
          }"
        />
      </div>
      <div class="mt-4 col-lg-3 col-md-6 col-12 mt-lg-0">
        <mini-statistics-card
          title="Anzahl der Ladungen"
          :value="statistic.amountofloads"
          description="&nbsp;"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-dark',
            shape: 'rounded-circle'
            }"
        />
      </div>
      <div class="mt-4 col-lg-3 col-md-6 col-12 mt-lg-0">
        <mini-statistics-card
          title="Zuletzt Geladen"
          :value="$filters.formatDate(statistic.last_loaded_ts)"
          :description="statistic.last_loaded_description"
          :icon="{
            component: 'ni ni-watch-time',
            background: 'bg-gradient-dark',
            shape: 'rounded-circle'
          }"
        />
      </div>
      <div class="mt-4 col-lg-3 col-md-6 col-12 mt-lg-0">
        <mini-statistics-card
          title="Letzte Ladung"
          :value="LastLoadedEnergy"
          description="&nbsp;"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-dark',
            shape: 'rounded-circle'
          }"
        />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        <progress-line-chart
          id="history-of-energy"
          title="Verlauf der Ladungen"
          :count="LoadedEnergyCHartSum"
          :progress="0"
          :chart="LoadedEnergyChart"
          chart_label="KWh"
          icon="chart-pie-35"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {Statistic} from "@/stores/statistic";
import {computed, onMounted, ref, watch} from "vue";
import MiniStatisticsCard from "@/components/Cards/MiniStatisticsCard.vue";
import ProgressLineChart from "@/components/Charts/ProgressLineChart.vue";

const sideloaded = ref(false)
const statistic = Statistic()
const LoadedEnergy = computed(() => {return `${statistic.loadedenergy.toFixed(2)} ${statistic.loadedenergy_unit}`})
const LastLoadedEnergy = computed(() => {return `${statistic.last_loaded_energy} ${statistic.last_loaded_energy_unit}`})
const LoadedEnergyChart = computed(() => {return statistic.loadedenergy_chart})
const LoadedEnergyCHartSum = computed(() => {return statistic.loadedenergy_chart.data.reduce((prev, current) => (parseFloat(prev) + parseFloat(current)).toFixed(2), 0)})

watch(()=>statistic.stat_table, ()=>{
  if(sideloaded.value){
    statistic.StatisticChart();
    statistic.ComputeStatisticHeader();
  }
})


onMounted(()=> {
  sideloaded.value = true;
  statistic.ComputeStatisticHeader();
  statistic.StatisticChart();
})

</script>

<style scoped>

</style>
