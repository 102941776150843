<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <argon-button full-width color="success" size="lg" variant="gradient" @click="goHome()">Startseite</argon-button>
    </div>
    <br />
    <div class="row">
      <statistic-header />
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Ladungen</h5>
                <p class="mb-0 text-sm">
                  Alle dargestellten Einträge sind Lokal auf diesem Gerät gespeichert.
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <argon-button variant="gradient" size="md" @click="CompleteStatisticData()"><i class="ni ni-cloud-download-95 ni-lg"></i> Statistik Aktualisieren</argon-button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table id="statistic-datatable" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th>Beschreibung</th>
                    <th>Geladene Energie</th>
                    <th>Start</th>
                    <th>Ende</th>
                    <th>Ladevorgang</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody v-for="item in statistic.stat_table" :key="item.id">
                  <tr>
                    <argon-checkbox :id="item.id"></argon-checkbox>
                    <td class="text-sm font-weight-normal">{{ item.transaction.tr_name }}</td>
                    <td class="text-sm font-weight-normal">{{ item.energy_consumption }} kWh</td>
                    <td class="text-sm font-weight-normal">{{ $filters.formatDateTime(item.transaction.ordered_at) }}</td>
                    <td class="text-sm font-weight-normal">{{ $filters.formatDateTime(item.created_at) }}</td>
                    <td class="text-sm font-weight-normal"><i v-if="item.loading" class="fas fa-spinner fa-spin"></i><i v-else class="fas fa-check-circle-o"></i></td>
                    <td class="text-sm font-weight-normal">
                      <argon-button variant="gradient" size="md" @click="showDetail(item.id)" title="Detail" data-bs-placement="top" data-bs-toggle="tooltip"><i class="ni ni-book-bookmark ni-lg"> </i></argon-button>&nbsp;
                      <argon-button variant="gradient" size="md" @click="deleteEntry(item.id)" title="Löschen" data-bs-placement="top" data-bs-toggle="tooltip"><i class="ni ni-fat-remove ni-lg"> </i></argon-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import StatisticHeader from "@/views/statisticpage/components/StatisticHeader.vue";
import {onMounted} from "vue";
import {Statistic} from "@/stores/statistic";
import ArgonButton from "@/components/ArgonButton.vue";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import {useRouter} from "vue-router";
import { Tooltip } from "bootstrap";

const router = useRouter();
const statistic = Statistic()


onMounted(async ()=>{
  new Tooltip(document.body, {selector: '[data-bs-toggle="tooltip"]'});
  await statistic.loadTable();
})

async function CompleteStatisticData(){
  let updated = await statistic.completeStatistic();
  Swal.fire({
    title: "Statistik Daten Aktualisiert!",
    text: `Es wurden ${updated} Datensätze aktualisiert.`,
    icon: "success",
    timer: 2000,
    showConfirmButton: false,
  })
  if(updated > 0){
    await statistic.loadTable();
  }
}

// eslint-disable-next-line no-unused-vars
function LoadingIcon(is_loading = false){
  if(is_loading){
    return `<i class="fa fa-spinner fa-spin"></i>`;
  }
  return `<i class="fa fa-check"></i>`;
}

async function showDetail(id = null){
  if(!id){return;}
  await router.push({name: "StatisticDetail", params: {id: id}});
}

async function deleteEntry(id = null){
  function ConvertDateTime(value){
    return dayjs(value).format("DD.MM.YYYY HH:mm");
  }

  if(!id){return;}
  const item = await statistic.getItem(id);
  const result = await Swal.fire({title: "Lösche Datensatz",
    text: `Bist du Sicher, den Datensatz vom ${ ConvertDateTime(item.created_at)} zu löschen?`,
    showCancelButton: true,
    confirmButtonText: "Löschen",
    cancelButtonText: "Abbrechen",
    reverseButtons: false,
    customClass: {
      confirmButton: "btn bg-gradient-danger",
      cancelButton: "btn bg-gradient-success",
    },
    buttonsStyling: false})
  if(result.isConfirmed) {
    await statistic.deleteItem(id);
    Swal.fire({
      title: "Datensatz Gelöscht!",
      text: "",
      icon: "success",
      timer: 2000
    })
    await statistic.loadTable();
  } else if(result.dismiss === Swal.DismissReason.cancel){
    //
  }
}

async function goHome(){
  await router.push({name: "Home"});
}

</script>

<style scoped>

.button.swal2-confirm.btn.bg-gradient-danger {
  margin-right: 5px;
}
.button.swal2-confirm.btn.bg-gradient-success {
  margin-left: 5px;
}

</style>
