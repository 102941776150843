<template>
  <nav
    id="navbarBlur"
    :class="`${
      !pgs.isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${
            pgs.darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    }`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <img v-if="provider_has_logo" :src="pgs.provider_logo" alt="Logo" height="83" />
      <img v-else src="@/assets/img/logos/technic-gmbh.png" alt="Technic Gerätebau GmbH" />
    </div>
  </nav>
</template>
<script setup>
import {computed, onBeforeUpdate} from "vue"
import { PageSettings } from "@/stores/page"

const pgs = PageSettings()
const provider_has_logo = computed(()=>{return pgs.provider_logo !== null})

async function toggleNavigationOnMobile() {
    if (window.innerWidth < 1200) {
        pgs.navbarMinimize()
    }
}

onBeforeUpdate(()=>{toggleNavigationOnMobile()})

</script>
