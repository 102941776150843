import Dexie from "dexie";

export const db = new Dexie("TransparentChargeApp");
db.version(3).stores({
  charges: "++id,created_at,provider,product_name,customer,energy_consumption,*labels",
  transactions: "++id,created_at,chargeid,provider,has_signature,*labels",
  carinformations: "++id,number_plate,*labels",
  labels: "++id,name"
})
db.version(2).stores({
  charges: "++id,created_at,provider,product_name,customer,*labels",
  transactions: "++id,created_at,chargeid,provider,has_signature,*labels",
  carinformations: "++id,number_plate,*labels",
  labels: "++id,name"
})
db.version(1).stores({
  charges: "++id,uid,created_at,provider,product_name,customer,last_charge,*labels",
  transactions: "++id,created_at,charge_uid,provider,has_signature,*labels",
  carinformations: "++id,number_plate,*labels",
  labels: "++id,name"
})
