import { defineStore } from "pinia";
import { db } from "@/db"
import dayjs from "dayjs";
import axios from "axios";
import {RTDB} from "@/stores/metric";


export const Statistic = defineStore('statistic', {
  state: () => ({
    loadedenergy: 0,
    loadedenergy_unit: 'kWh',
    loadedenergy_last_calculated: "1970-01-01T08:00:00",
    amountofloads: 0,
    loadedenergy_chart: {labels: [], data: []},
    last_loaded_ts: "2023-04-20T14:00:00",
    last_loaded_energy: 0,
    last_loaded_energy_unit: 'kWh',
    last_loaded_id: "",
    last_loaded_description: "",
    stat_table: []
  }),
  actions: {
    async ComputeStatisticHeader(){
      const energy = await db.charges.orderBy('created_at').desc().limit(50).toArray()

      let lastentry = energy[0]
      this.amountofloads = energy.length
      this.loadedenergy = 0
      this.last_loaded_energy = lastentry.energy_consumption
      this.last_loaded_id = lastentry.id
      this.last_loaded_ts = lastentry.created_at

      for(let i = 0; i < energy.length; i++){
        this.loadedenergy += parseFloat(energy[i].energy_consumption)
      }

      if(energy.length > 0){
        let pre_last = energy[1]
        let calc_before_cons = (parseFloat(lastentry.energy_consumption) - parseFloat(pre_last.energy_consumption))
        if(calc_before_cons > 0){
          this.last_loaded_description = `<span class="text-sm font-weight-bolder text-danger">+${calc_before_cons.toFixed(2)}</span> kWh mehr geladen`
        } else if(calc_before_cons < 0) {
          calc_before_cons = (0 - calc_before_cons)
          this.last_loaded_description = `<span class="text-sm font-weight-bolder text-success">${calc_before_cons.toFixed(2)}</span> kWh weniger geladen`
        }
      }
    },
    async getItems(limit = 50){
      return await db.charges.orderBy('created_at').desc().limit(limit).toArray()
    },
    async loadTable(){
      this.stat_table = await this.getItems(50)
    },
    async StatisticChart() {
      function ConvertDate(value) {
        return dayjs(value).format("DD.MM.YYYY");
      }

      const dbdata = await this.getItems(50);
      let chartdata = {}

      for (let i = 0; i < dbdata.length; i++) {
        let dt = ConvertDate(dbdata[i].created_at);
        if (!(dt in chartdata)) {
          chartdata[dt] = 0.0;
        }
        chartdata[dt] += parseFloat(dbdata[i].energy_consumption);
      }
      this.loadedenergy_chart = {labels: [], data: []}
      Object.keys(chartdata).reverse().forEach(key => {
        this.loadedenergy_chart.labels.push(key);
        this.loadedenergy_chart.data.push(chartdata[key].toFixed(2));
      })
      return await this.loadedenergy_chart
    },
    async getItem(id){
      return await db.charges.get({id: id})
    },
    async deleteItem(id) {
      return await db.charges.delete(id)
    },
    async updateStatistic(id = null, data = {}){
      const metric = RTDB()
      if(!id || !data){return}

      let existing_data = await this.getItem(id)
      if(existing_data){
        if(data){
          Object.keys(data).forEach(key => {existing_data[key] = data[key]})
        }
        data = existing_data
      }

      data['energychart'] = await metric.ConvertChart(data['energychart'])
      data['powerchart'] = await metric.ConvertChart(data['powerchart'])

      // cleanup object
      let to_delete = ['settings', 'adv', 'loading']
      for(let i = 0; i < to_delete.length; i++) {
        if (to_delete[i] in data) {
          delete data[to_delete[i]]
        }
      }
      return await db.charges.put(JSON.parse(JSON.stringify(data)), data.id)
    },
    async completeStatistic(){
      let updated = 0
      const charges = await db.charges.orderBy('created_at').desc().filter((item) => !(/powermeter/i.test(Object.keys(item.transaction)))).toArray()
      for(let i = 0; i < charges.length; i++){
        let resp = await axios.get(`/api/v1/transaction/load/${charges[i].id}`)
        if(!resp.status === 200){continue}
        let data = resp.data
        if(!("transaction" in data)){continue}
        await this.updateStatistic(data.id, data)
        updated++
      }
      return updated
    }
  }
})
