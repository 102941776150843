<template>
  <div class="card shadow-lg">
    <img
      src="../../../assets/img/shapes/pattern-left.png"
      alt="pattern-lines"
      class="position-absolute overflow-hidden opacity-4 start-0 top-0 h-100 border-radius-xl"
    />
    <img
      src="../../../assets/img/shapes/pattern-right.png"
      alt="pattern-lines"
      class="position-absolute overflow-hidden opacity-4 end-0 top-0 h-100 border-radius-xl"
    />
    <div class="card-body px-5 z-index-1 bg-cover">
      <div class="row">
        <div class="col-lg-3 col-12 my-auto" v-if="props.show">
          <h4 class="text-body opacity-9 text-center">{{ props.name }}</h4>
          <hr class="horizontal light mt-1 mb-3" />
          <div v-if="!metrics.is_customer" class="d-flex justify-content-center">
            <div>
              <h6 class="mb-0 text-body opacity-7">Bezahlt</h6>
              <h3 class="text-body">
                {{ metrics.payed }}
                <small class="text-sm align-top">{{ props.currency }}</small>
              </h3>
            </div>
            <div class="ms-lg-6 ms-4 text-center">
              <h6 class="mb-0 text-body opacity-7">Energie</h6>
              <h3 class="text-body">
                  {{ metrics.energy_settings.maxKWh }}
                <small class="text-sm align-top">{{ props.power_unit }}</small>
              </h3>
            </div>
          </div>
          <argon-button full-width color="success" variant="outline" size="md" @click="goStatistic()"><i class="ni ni-chart-bar-32 ni-lg"></i>&nbsp; Statistik</argon-button>
        </div>
        <div class="col-lg-3 col-12 my-auto" v-else></div>
        <div class="col-lg-6 col-12 text-center">
          <img
            class="w-75 w-lg-100 mt-n7 mt-lg-n8 d-none d-md-block"
            src="../../../assets/img/charge_ionic_technic.png"
            alt="car image"
          />
          <div v-if="metrics.loading" class="d-flex align-items-center">
            <h4 class="text-body opacity-7 ms-0 ms-md-auto">Ladung</h4>
            <h2 v-if="!metrics.is_customer" class="text-body ms-2 me-auto">
              {{ metrics.progress }}
              <small class="text-sm align-top">%</small>
            </h2>
            <h2 v-else class="text-body ms-2 me-auto">
              &infin;
              <small class="text-sm align-top">&nbsp;</small>
            </h2>
          </div>
          <div v-else class="d-flex align-items-center">
            <h4 class="text-body opacity-7 ms-0 ms-md-auto">Ladung Abgeschlossen</h4>
            <h2 class="text-body ms-2 me-auto">
              <small class="text-sm align-top"></small>
            </h2>
          </div>
        </div>
        <div class="col-lg-3 col-12 my-auto">
          <h4 class="text-body opacity-9">{{ metrics.adv_title }}</h4>
          <hr class="horizontal light mt-1 mb-3" />
          <div class="d-flex align-items-center">
            <div>
              <h6 v-for="(item, index) in adv_text" :key="index" class="mb-0 text-body">
                  {{ item }}
              </h6>
                <br />
              <argon-button v-if="metrics.adv_link"
                            color="success"
                            size="md"
                            variant="outline"
                            @click="adv_clickevent(metrics.adv_link)"
                            full-width>
                  {{adv_link_title}}
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps} from 'vue'
import ArgonButton from "@/components/ArgonButton.vue";
import {RTDB} from "@/stores/metric";
import {useRouter} from "vue-router";

const router = useRouter()
const metrics = RTDB()


const props = defineProps({
    show: {
        type: Boolean,
        default: true
    },
    name: {
        type: String,
        default: 'EV-Ladung'
    },
    price: {
        type: String,
        default: "5,6"
    },
    currency: {
        type: String,
        default: "€"
    },
    power: {
        type: String,
        default: "2400"
    },
    power_unit: {
        type: String,
        default: "kWh"
    },
    maplink: {
        type: String,
        default: null,
    }
})

// const show_link = computed(() => {return props.adv_link !== null})
const adv_text = computed(() => {
    if (metrics.adv_text) {
        return metrics.adv_text.split('\n');
    }
    return [];
})

const adv_link_title = computed(() => {
  if(!metrics.adv_link_title){
    return metrics.adv_title
  }
  return metrics.adv_link_title
})

async function adv_clickevent(link = null){
  if(link){window.open(link, '_blank')}
}

async function goStatistic(){
  await router.push({name: 'Statistic'})
}

</script>
