<template>
<div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <card-detail />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card
          v-if="!metrics.is_customer"
          class="bg-success"
          :title="{ text: 'voraussichtliches Ende der Ladung', color: 'opacity-7 text-white' }"
          :value="{ text: $filters.formatDateTime(metrics.transaction_end), color: 'text-white' }"
          :icon="{
            component: 'ni ni-watch-time text-dark',
            background: 'bg-white',
            shape: 'rounded-circle',
          }"
        />
      </div>
      <div class="mt-4 col-lg-3 col-md-6 col-12 mt-md-0">
        <mini-statistics-card
          class="bg-success"
          :title="{ text: 'Abgegebene Energie', color: 'opacity-7 text-white' }"
          :value="{ text: energy_consumption, color: 'text-white' }"
          :icon="{
            component: 'ni ni-sound-wave text-dark',
            background: 'bg-white',
            shape: 'rounded-circle',
          }"
        />
      </div>
      <div class="mt-4 col-lg-3 col-md-6 col-12 mt-lg-0">
        <mini-statistics-card
          class="bg-success"
          :title="{ text: 'aktuelle Ladeleistung', color: 'opacity-7 text-white' }"
          :value="{ text: actual_power, color: 'text-white' }"
          :icon="{
            component: 'ni ni-sound-wave text-dark',
            background: 'bg-white',
            shape: 'rounded-circle',
          }"
        />
      </div>
      <div class="mt-4 col-lg-3 col-md-6 col-12 mt-lg-0">
        <mini-statistics-card
          v-if="!metrics.is_customer"
          class="bg-success"
          :title="{ text: 'Parkzeit Ende', color: 'opacity-7 text-white' }"
          :value="{ text: $filters.formatDateTime(metrics.transaction_end), color: 'text-white' }"
          :icon="{
            component: 'ni ni-watch-time text-dark',
            background: 'bg-white',
            shape: 'rounded-circle',
          }"
        />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-md-6">
          <default-line-chart v-bind="{
                  id: 'verlauf-lade-energie',
                  title: 'Verlauf Energie',
                  height: '250',
                  chart: energychart,
                  pointradius: 0}"
          />
      </div>
      <div class="col-md-6">
          <default-line-chart v-bind="{
                  id: 'verlauf-ladeleistung',
                  title: 'Verlauf Ladeleistung',
                  height: '250',
                  chart: powerchart,
                  pointradius: 0
                  }"
          />
      </div>
    </div>
  </div>
</template>

<script setup>
import CardDetail from "@/views/chargingpage/components/CardDetail.vue";
import MiniStatisticsCard from "@/components/Cards/MiniStatisticsCard.vue";
import {computed, onMounted, watch} from "vue";
import DefaultLineChart from "@/components/Charts/DefaultLineChart.vue";
import {RTDB} from "@/stores/metric";
import {PageSettings} from "@/stores/page";
// import setTooltip from "@/assets/js/tooltip.js";
const metrics = RTDB()
const pgs = PageSettings()

const powerchart = computed(()=>{
    return metrics.powerchart
})
const energychart = computed(()=>{
    return metrics.energychart
})
const energy_consumption = computed(()=>{
    return `${metrics.energy_consumption} kWh`
})
const actual_power = computed(()=>{
    return `${metrics.actual_power} kW`
})


watch(()=>pgs.uid, (current_uid, prev_uid)=>{
  if(current_uid && current_uid !== prev_uid){
    metrics.loadData(current_uid)
  }
})

onMounted( async () => {
  await metrics.loadData(pgs.uid)
})

</script>

<style scoped>

</style>
