import {initializeApp} from "firebase/app";
import {getDatabase} from "firebase/database";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBzaxhep0mkB7NqoLSe_Cn-Bmyb3RX3vfI",
  authDomain: "transparentchargeapp.firebaseapp.com",
  databaseURL: "https://transparentchargeapp-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "transparentchargeapp",
  storageBucket: "transparentchargeapp.appspot.com",
  messagingSenderId: "212685879994",
  appId: "1:212685879994:web:ab9074e43d01fe23d52083"
};

const fdbapp = initializeApp(firebaseConfig)
export const fdb = getDatabase(fdbapp);

export const messaging = getMessaging(fdbapp);
