import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App'
import axios from 'axios'
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import router from "./router";
import messaging from "./firebase";
import dayjs from "dayjs";

const pinia = createPinia()
const app = createApp(App)

if (process.env.NODE_ENV === 'production') {
  app.config.devtools = false;
  app.config.debug = false;
  app.config.silent = true;
}

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

app.config.globalProperties.$messaging = messaging;
app.config.globalProperties.$http = axios
app.config.globalProperties.$filters = {
  formatDate(value, template = 'DD.MM.YYYY') {
    if (value) {
      return dayjs(String(value)).format(template)
    }
  },
  formatTime(value, template = 'HH:mm:ss') {
    if (value) {
      return dayjs(String(value)).format(template)
    }
  },
  formatDateTime(value, template = 'DD.MM.YYYY HH:mm'){
    if (value) {
      return dayjs(String(value)).format(template)
    }
  },
  currency(value, sign='€') {
    return `${value} ${sign}`
  },
  b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }
}
app.use(pinia)
app.use(router)
app.use(VueTilt)
app.use(VueSweetalert2)
app.use(ArgonDashboard)
app.mount('#app')
